.footer {
  background-color: #282c34; // Couleur de fond sombre
  color: #ffffff; // Couleur du texte en blanc
  padding: 1rem 2rem; // Espacement intérieur
  text-align: center; // Centrer le texte
  border-top: 1px solid #444; // Bordure supérieure
  font-size: 0.875rem; // Taille de la police plus petite
}

.footer-content {
  margin: 0 auto; // Centrer le contenu horizontalement

  .footer-links {
    margin-top: 0.5rem; // Espacement au-dessus des liens
    a {
      color: #61dafb; // Couleur des liens
      margin: 0 0.5rem; // Espacement horizontal entre les liens
      text-decoration: none; // Supprimer la décoration des liens
      &:hover {
        text-decoration: underline; // Souligner au survol
      }
    }
  }
}

.footer-tmdb {
  display: flex; // Afficher en ligne
  justify-content: center; // Centrer horizontalement
  align-items: center; // Centrer verticalement
  gap: 0.5rem; // Espacement entre les éléments
  .tmdb-logo {
    width: 50px; // Largeur du logo
  }
}
