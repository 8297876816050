.hover-content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.card:hover .hover-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  backdrop-filter: blur(3px) brightness(0.5);
  border-radius: 8px;
}

.item-card:hover .hover-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px) brightness(0.5);
  border-radius: 8px;
}

.dialog-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: var(--primary-color);

  white-space: pre-wrap; /* Respecte les tabulations et les retours à la ligne */
  word-wrap: break-word; /* Permet de passer à la ligne quand une ligne est trop longue */
  overflow-wrap: break-word; /* Compatibilité supplémentaire pour éviter le débordement */
  max-width: 100%; /* Limite la largeur du commentaire au conteneur */
}

.hover-comment {
  //max 2 lines of text and ellipsis
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
}
.card-hover-title {
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
}

@media (hover: none) and (pointer: coarse) {
  .hover-content {
    pointer-events: none;
  }
  .hover-content.clicked {
    pointer-events: auto; // Permet l'interaction lorsque visible
  }
  .hover-comment {
    display: none;
  }
  .card-hover-title {
    display: none;
  }
}
