.error-container {
  text-align: center;
  padding: 20px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin: 20px;
}

.error-container h1 {
  margin: 0;
  font-size: 24px;
}

.error-container p {
  margin: 10px 0;
}

.error-container button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
