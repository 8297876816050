.list-of-lists {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.textarea-search-comment {
  max-width: max-content;
  min-width: 100%;
  min-height: 50px;
  overflow: hidden;
}

.add-list-dialog-header {
  display: flex;
  flex-direction: column-reverse;
}

.add-list-close {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
