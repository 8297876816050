.settings-title {
  font-weight: bold;
  color: var(--primary-color);
  padding: 20px;
}

.settings-container {
  padding: 20px;
}

.settings-grid {
  margin-top: 20px;
}

.settings-grid .MuiGrid-item {
  padding: 20px;
}
