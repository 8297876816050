.contact-container {
  display: flex; // Afficher en ligne
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.button-back {
  padding: 20px;
}
