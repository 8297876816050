// Dark theme
:root {
  --body-bg: #0a0736;
  --body-bg-lighter: #31204a;
  --body-bg-darker: #060423;
  --primary-color: #f77c09;
  --primary-color-lighter: #f98a2b;
  --primary-color-darker: #d56a07;
  --secondary-color: #ececec;
  --secondary-color-darker: #b3b3b3;
  --secondary-color-lighter: #e6e6e6;
  --disabled-color: #9e9e9e56;
}

// Light theme
[data-theme='light'] {
  --body-bg: #ececec;
  --body-bg-lighter: #e6e6e6;
  --body-bg-darker: #b3b3b3;
  --primary-color: #f77c09;
  --primary-color-lighter: #f98a2b;
  --primary-color-darker: #d56a07;
  --secondary-color: #0a0736;
  --secondary-color-lighter: #31204a;
  --secondary-color-darker: #060423;
  --disabled-color: #9e9e9e56;
}

$white: #fff;
$grey: #636363;
$black: #000;
$delete-color: #ff0000;
$delete-color-darker: #cc0000;
$add-color: #4caf50;
$add-color-darker: #388e3c;
$disabled-color: #9e9e9e9f;

$box-shadow-card: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$box-shadow-arrow: var(--primary-color, 0.32) 0px 2px 10px 0px;

$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 30px;

$little-mobile-width: 360px;
$mobile-width: 600px;
$tablet-width: 1024px;
