@use '../../scss/' as *;

.activity-header {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.activity-search {
  width: auto;
}

.h1-activities {
  font-weight: bold;
  color: var(--primary-color);
  padding: 20px;

  @include mobile {
    padding-bottom: 0px;
  }
}

.activities-container {
  width: 100vw;
  padding: 15px;
}

.activity-separator {
  border: 0;
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: var(--primary-color);
}

.activity-container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 15px;
  height: 300px;
  overflow: hidden;

  @include tablet {
    width: 99%;
    height: 200px;
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.activity-container img {
  height: 262px;
  width: 185px;

  @include tablet {
    height: 200px;
    width: 141px;
  }

  @include mobile {
    height: 150px;
    width: 106px;
  }
}

.activity-content-container {
  display: flex;
  flex: 3;
  width: 80%;
  height: 262px;
  flex-direction: column;
  padding-left: 15px;
  justify-content: center;

  @include tablet {
    height: 200px;
    width: 50%;
  }
}

.activity-date {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;

  @include tablet {
    justify-content: flex-start;
  }
}

.cliquable-redirect {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color-darker);
  }
}

.activity-comment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  padding-bottom: 10px;
}

.activity-comment-text {
  width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.activity-rating {
  height: 20%;
  display: flex;
  align-items: flex-end;
}

.activity-see-button {
  white-space: nowrap;
  font-size: clamp(12px, 2vw, 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.loading-space {
  height: 200px; // Hauteur de l'espace réservé
  background: var(--secondary-color-lighter); // Couleur de fond ou image de chargement
}
