@use '../../scss/' as *;

.discover-container {
  padding: 20px;
}

.card-carousel {
  border-radius: 8px;
  display: flex;
  width: 200px;
  height: 350px;

  flex-direction: column;
  box-shadow: $box-shadow-card;

  @include tablet {
    width: 150px;
    height: 265px;
  }
}

.image-container img {
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.react-multi-carousel-list {
  height: 400px;

  @include tablet {
    height: 300px;
  }
}

.react-multi-carousel-item {
  width: 200px !important;
  margin: 30px;

  @include tablet {
    width: 150px !important;
    margin: 5px;
  }
}
