@use '../../scss/' as *;

//spinner
.spinner-search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  backdrop-filter: blur(5px); // Appliquer un flou au fond
  border-radius: 10px;
  padding: 100px;
}

.research-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.discover-search {
  width: 60% !important;

  @include mobile {
    width: 100% !important;
  }
}

//cards
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;

  @include little-mobile {
    gap: 15px;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 350px;
  border-radius: 8px;
  box-shadow: $box-shadow-card;

  @include tablet {
    width: 150px;
    height: 265px;
  }

  @include mobile {
    width: 90px;
    height: 165px;
  }
}

//loadMore
.load-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: var(--primary-color);
}
