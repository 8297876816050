.about-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.about-container li {
  //bold
  font-weight: 600;
}

.about-section {
  margin-bottom: 30px;
}

.about-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.about-section p,
.about-section ul {
  font-size: 1em;
  line-height: 1.6;
}

.about-section ul {
  list-style-type: disc;
  margin-left: 20px;
}
